<template>
    <div class="history-order">
        <div class="container">
<!--            <router-link :to="{ name: 'history'}" class="go-back">
                Повернутися до історії замовлень
            </router-link>-->
          <a href="javascript:void(0);" class="go-back" @click="$router.go(-1)">
            Повернутися
          </a>
            <div :class="['history-order__wrapp',{'disabled':documentDisabled}]">
                <div class="history-order__item">
                    <div class="history-order__head">
                        Страховий продукт
                    </div>
                    <div class="history-order__form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Компанія</label>
                                <input type="text" class="form-control" v-model="order.companyInfo.name" disabled>
                            </div>
                            <div v-if="order.typeId == this.orderTypes['osago']" class="col-md-4">
                                <label>Франшиза, грн</label>
                                <input type="text" class="form-control" v-model="order.offer.franchise" disabled>
                            </div>
<!--                            <div class="col-md-4">-->
<!--                                <label>Тариф</label>-->
<!--                                <input type="text"  class="form-control" v-model="order.insuranceObject.vin" disabled>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div v-if="order.typeId == this.orderTypes['dgo']" class="history-order__form-group">
                        <div class="history-order__form-heading">ДЦВ:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Вартість, грн</label>
                                <input  disabled v-model="order.dgo.price" class="form-control date-selector">
                            </div>
                            <div class="col-md-4">
                                <label>Компенсація, грн</label>
                                <input  disabled v-model="order.dgo.compensation" class="form-control date-selector">
                            </div>
                        </div>
                    </div>
                    <div v-if="order.typeId == this.orderTypes['autolawyer']" class="history-order__form-group">
                        <div class="history-order__form-heading">Автоюрист:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Вартість, грн</label>
                                <input  disabled v-model="order.autolawyer.price" class="form-control date-selector">
                            </div>
                            <div class="col-md-4">
                                <label>Программа</label>
                                <input  disabled :value="showAutolawyerProgram(order)" class="form-control date-selector">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="history-order__item">
                    <div class="history-order__head">
                        Розрахунок
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Дата дії договору:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Початок</label>
                                <input  v-if="skeletonOff" disabled v-model="order.contract.startedAt" class="form-control date-selector">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Закінчення</label>
                                <input v-if="skeletonOff"  disabled v-model="order.contract.finishedAt" class="form-control date-selector">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Розрахунковий період</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="orderPeriod" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Тип ТЗ (категорія):</div>
                        <div class="row">
                            <div class="col-md-8">
                                <input v-if="skeletonOff"  :disabled="documentDisabled" :value="showAutoCategory()" class="form-control" >
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Місце реєстрації власника ТЗ:</div>
                        <div class="row">
                            <div class="col-md-8 form-group">
                                <input v-if="skeletonOff"  :disabled="documentDisabled" :value="showCityRegister()" class="form-control" >
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="history-order__notification col-md-8">
                                <div class="history-order__notification__wrapp">
                                    <div class="history-order__notification__icon">
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 23.4C15.7583 23.4 18.4035 22.3043 20.3539 20.3539C22.3043 18.4035 23.4 15.7583 23.4 13C23.4 10.2417 22.3043 7.59647 20.3539 5.64609C18.4035 3.69571 15.7583 2.6 13 2.6C10.2417 2.6 7.59647 3.69571 5.64609 5.64609C3.69571 7.59647 2.6 10.2417 2.6 13C2.6 15.7583 3.69571 18.4035 5.64609 20.3539C7.59647 22.3043 10.2417 23.4 13 23.4ZM13 26C5.8201 26 0 20.1799 0 13C0 5.8201 5.8201 0 13 0C20.1799 0 26 5.8201 26 13C26 20.1799 20.1799 26 13 26ZM11.7 18.2H14.3V20.8H11.7V18.2ZM11.7 5.2H14.3V15.6H11.7V5.2Z" fill="#FA9A13"/>
                                        </svg>
                                        <b>Важливо</b>
                                    </div>
                                    <p>Місце реєстрації (проживання) власника транспортного засобу (фізичної особи) або місцезнаходження юридичної особи відповідно до документа про реєстрацію транспортного засобу</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__item history-order__client">
                    <div class="history-order__head">
                        Дані страхувальника
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Ідентифікація:</div>
                        <div class="row">
                            <div class="col-md-4">
                               	<label>{{isLegalType() ? 'ЄДРПОУ' : 'ІПН' }}</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="order.insurant.documentation.uin" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div v-if="! isLegalType()" class="col-md-4">
                                <label>Дата народження</label>
                                <input v-if="skeletonOff" v-model="order.insurant.dateBirthAt" class="form-control date-selector" placeholder="" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div v-if="! isLegalType()" class="col-md-4">
                                <label>Прізвище</label>
                                <input v-if="skeletonOff" type="text" placeholder="" class="form-control" v-model="order.insurant.surname" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div :class="isLegalType() ? 'col-md-12' : 'col-md-4'">
                                <label>Ім'я</label>
                                <input v-if="skeletonOff" type="text" placeholder="" class="form-control" v-model="order.insurant.name" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div v-if="! isLegalType()" class="col-md-4">
                                <label>По батькові</label>
                                <input v-if="skeletonOff" type="text" placeholder="" class="form-control" v-model="order.insurant.patronymic" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Контактна інформація:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Телефон</label>
                                <input v-if="skeletonOff" type="text" placeholder="Телефон" class="form-control" v-model="order.insurant.phone" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Email</label>
                                <input v-if="skeletonOff" type="text" placeholder="Email" class="form-control" v-model="order.insurant.email" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Документ, що засвідчує особу:</label>
                                <input v-if="skeletonOff" type="text" class="form-control" :value="showTypeDocument()" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Запис №</label>
                                <input v-if="skeletonOff" type="text" placeholder="Запись №" class="form-control" v-model="order.insurant.documentation.serial" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div v-if="!this.isDocumentERetiree(order.insurant.privilegeTypeId)" class="col-md-4">
                                <label>Документ №</label>
                                <input v-if="skeletonOff" type="text" placeholder="Документ №" class="form-control" v-model="order.insurant.documentation.number" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>{{ !this.isDocumentERetiree(order.insurant.privilegeTypeId) ? 'Дата выдачі' : 'Закінчення дії' }}</label>
                                <input v-if="skeletonOff" v-model="order.insurant.documentation.dateOfIssue" class="form-control date-selector" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-8">
                                <label>Орган, що видав</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="order.insurant.documentation.issuedBy" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__item">
                    <div class="history-order__head">
                        Дані автомобіля
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Ідентифікація:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Держ. номер</label>
                                <input v-if="skeletonOff" type="text" placeholder="Держ. номер" class="form-control" v-model="order.insuranceObject.number" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Номер кузова (VIN-код)</label>
                                <input v-if="skeletonOff" type="text" placeholder="Номер кузова (VIN-код)" class="form-control" v-model="order.insuranceObject.vin" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Автомобіль:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Модель</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="order.insuranceObject.brand" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Марка</label>
                                <input v-if="skeletonOff" type="text"  class="form-control" v-model="order.insuranceObject.model" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Рік випуску авто</label>
                                <input v-if="skeletonOff" type="text"  class="form-control" v-model="order.insuranceObject.year" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__item">
                    <div class="history-order__head">
                        Інше
                    </div>
                    <div class="history-order__form-group">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Примітки:</label>
                                <textarea v-if="skeletonOff" class="form-control" disabled></textarea>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group border-none m-b-none">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Створити на підставі даного договору новий договір:</label>
                                <select v-if="skeletonOff" class="form-control" disabled>
                                    <option value="1">ОСЦВ (электронный)</option>
                                </select>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__price">
                    <div class="row">
                        <div class="col-md-8">
 <!--
                            <div v-if="showPriceDgo()" class="history-order__price__item">
                                <p>Тариф ДЦВ:</p>
                                <b> {{ showPriceDgo() }} <span>грн</span></b>
                            </div>
-->
                            <div class="history-order__price__item">
                                <p>Первісна вартість:</p>
                                <b> {{ showPrice(order) }} <span>грн</span></b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__price col-lg-12" v-if="order.typeId == this.orderTypes['osago'] && canUsageMonths()">
                    <div class="osago-issue-page selectors filter-row">
                        <div class="info-block">
                            <b>ТЗ НЕ використовується протягом календарних мiсяцiв</b>
                            <div class="filter-body row">
                                <div class="col-lg-2 large-checkbox div_circle" v-for="month in monthList" :key="month.id">
                                    <input name="month" type="checkbox" :id="'month_'+month.id" :value="month" v-model="months" disabled="disabled">
                                    <label :for="'month_'+month.id">
                                        <i class="icon icon-directSettlement"></i>
                                    </label>
                                    <span class="icon-text">{{ month.id }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div class="history-order__price" v-if="canUsageMonths()">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="history-order__price__item">
                                <p>Вартість зі знижкою:</p>
                                <b>{{getFinishPrice()}}<span>грн</span></b>
                            </div>
                        </div>
                    </div>
                </div>-->
                <div class="history-order__price" v-if="!canUsageMonths()">
                    <div class="row">
                        <div class="col-md-8">
                            <div v-if="showDiscountPrice()" class="history-order__price__item">
                                <p>Вартість зі знижкою:</p>
                                <b> {{ getFinishPrice() }} <span>грн</span></b>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div class="history-order__price">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="history-order__price__item">
                                <p>Загальна вартість:</p>
                                <b> {{ showPrice() }} <span>грн</span></b>
                            </div>
                        </div>
                    </div>
                </div>-->
                <div class="history-order__nav">
                    <div class="row">

                    </div>
                </div>

                <div v-if="this.loadData" class="partner-order__nav">
                    <div class="row">
                        <div class="col-md-8" v-if="order.edit">
                            <div class="row">
                                <div v-if="order.statusId == 1" class="col-md-6">
                                    <button type="button" @click="declare(order)" class="btn-blue-w">Редагувати і заявити</button>
                                </div>
                                <div v-if="order.statusId == 5" class="col-md-6">
                                    <button :disabled="isСoncludeProcess" type="button" @click="declare(order,false,false,2)" class="btn-blue-w">
                                        <span v-if="isСoncludeProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span v-else>Продовжити</span>
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button type="button" @click="draft()" class="btn btn-dark">Переглянути чернетку</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="order.typeId == this.orderTypes['osago']" class="col-md-4">
                            <button :disabled="isCopyProcess" type="button" @click="copy(order)" class="btn btn-success">
                                <span v-if="isCopyProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-else>Копіювати</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import { calculate } from '../../api/offer';
const FileDownload = require('js-file-download');
import { showOrder, concludeOrder } from '../../api/order';
import { customerMixin, HistoryMixin } from "../../mixin";

export default {
    components: {Multiselect},
    mixins: [customerMixin, HistoryMixin],
    data: function () {
        return {
            loadMoreOptions: {},
            loadData: false,
            skeletonOff: false,
            isCopyProcess: false,
            isСoncludeProcess: false,
            togglerVisible: true,
            documentDisabled: true,
            orderPeriod: '1 рік',
            order: {
                offer: {},
                contract: {},
                companyInfo: {},
                insuranceObject: {},
                insurant:{
                    documentation: {},
                }
            },
            periodOptions: [
                {"id": 15, "count":"15", "key":"d", "name" : "15 д" },
                {"id": 1, "count":"1", "key":"m", "name" : "1 міс" },
                {"id": 2, "count":"2", "key":"m", "name" : "2 міс" },
                {"id": 3, "count":"3", "key":"m", "name" : "3 міс" },
                {"id": 4, "count":"4", "key":"m", "name" : "4 міс" },
                {"id": 5, "count":"5", "key":"m", "name" : "5 міс" },
                {"id": 6, "count":"6", "key":"m", "name" : "6 міс" },
                {"id": 7, "count":"7", "key":"m", "name" : "7 міс" },
                {"id": 8, "count":"8", "key":"m", "name" : "8 міс" },
                {"id": 9, "count":"9", "key":"m", "name" : "9 міс" },
                {"id": 10, "count":"10", "key":"m", "name" : "10 міс" },
                {"id": 11, "count":"11", "key":"m", "name" : "11 міс" },
                {"id": 12, "count":"12", "key":"m", "name" : "12 міс" },
            ],
            monthList: [{'id': 1},{'id': 2},{'id': 3},{'id': 4},{'id': 5},{'id': 6},{'id': 7},{'id': 8},{'id': 9},{'id': 10},{'id': 11},{'id': 12}],
            months: [],
            months2: [],
        };
    },

     async created() {
        this.order = await showOrder(this.$route.params.orderId, this.$route.params.typeId).then((response) => {
            return response.data.data;
        });

         this.loadData = true;
         this.skeletonOff = true;

         this.getContractPeriod();

         //console.log('+this.order.usageMonths+',this.order.usageMonths);
         if(this.order.usageMonths != null && this.order.usageMonths != undefined && this.order.usageMonths != '')
         {
             if(Array.isArray(this.order.usageMonths))
             {
                 if(this.order.usageMonths.length > 0)
                 {
                     this.order.usageMonths.forEach((item, key) => {

                         if(item != 0)
                         {
                             this.months2.push({id:(key+1)});
                         }
                     });
                 }
             }
         }
         //console.log('this.months2',this.months2);
         this.months = this.months2;
         //console.log('this.months',this.months);
     },

    mounted () {
        this.getContractPeriod();
    },

    computed: {
        orderTypes: function () {
            return this.$store.getters.ORDER_TYPES
        }
    },

    methods: {
        showPriceDgo() {
            return parseFloat((this.order?.dgo?.price || 0));
        },
        showAutolawyerProgram(order) {
            let program = order.autolawyer.program ?? 1;
            switch (program) {
                case 2:
                    return "Комфорт";
                case 3:
                    return "Комфорт плюс";
                default:
                    return "Стандарт";
            }
            return program;
        },
        getFinishPrice()
        {
            //console.log('getFinishPrice', this.order);
            let finishPrice = this.showDiscountPrice();
            //console.log('getFinishPrice', finishPrice);
            if(finishPrice == 0)
                finishPrice = this.showMonthsOnPrice();

            /*if(this.order.discountPercent > 0)
                finishPrice = Number( finishPrice-(finishPrice/100*this.order.discountPercent))*/
            //finishPrice = finishPrice + Number(this.order.dgoPrice || 0);
            //console.log(finishPrice);
            finishPrice = Number( finishPrice);// + parseFloat((this.order?.dgo?.price || 0));
            //console.log('getFinishPrice DGO', finishPrice);
            return finishPrice;
        },
        canUsageMonths()
        {
            if (this.order.typeId == this.orderTypes['dgo']) {
                return false;
            }
            //console.log('call canUsageMonths');
            return this.order.usageMonths != null && this.order.usageMonths != undefined && this.order.usageMonths != '';
        },
        draft() {
            window.open(`/api/orders/draft/${this.$store.getters.USER.id}/${this.$route.params.orderId}`);
        },
        /*showPrice() {
            let price;

            if (this.order.typeId == this.orderTypes['osago']) {
                price = parseFloat(this.order.price);
            } else if (this.order.typeId == this.orderTypes['dgo']) {
                price = parseFloat((this.order?.dgo?.price || 0));
            } else if (this.order.typeId == this.orderTypes['autolawyer']) {
                price = parseFloat((this.order?.autolawyer?.price || 0));
            } else {

            }
            //let price = this.order.typeId == this.orderTypes['osago'] ? parseFloat(this.order.price) : parseFloat((this.order?.dgo?.price || 0));

            if (isNaN(price)) {
                return 0;
            }

            return price.toFixed(2);
        },*/
        showDiscountPrice() {

            if (this.order.typeId == this.orderTypes['dgo'] || this.order.typeId == this.orderTypes['autolawyer']) {
                return 0;
            }

            let finishPrice = this.showMonthsOnPrice();

            if(this.order.discountPrice > 0)
            {
                var realDiscountPercent = 100 - this.order.discountPrice * 100 / this.order.price;
                finishPrice = Number(finishPrice - (finishPrice / 100 * realDiscountPercent)).toFixed(2);
                //finishPrice = Number( finishPrice-(finishPrice - this.order.discountPrice)).toFixed(2);
                //console.log('showDiscountPrice Percent', finishPrice);
                return finishPrice;
            }

            /*if (this.order.discountPrice) {
                let price = parseFloat(this.order.discountPrice);

                if (isNaN(price)) {
                    return 0;
                }

                return price.toFixed(2);
            }*/

            return 0;
        },
        showMonthsOnPrice()
        {
            let usageMonthsOn = 0;
            //console.log('this.order.usageMonths', this.order.usageMonths)
            if(this.order.usageMonths != null && this.order.usageMonths != undefined && this.order.usageMonths != '')
            {
                if(Array.isArray(this.order.usageMonths))
                {
                    if(this.order.usageMonths.length > 0)
                    {
                        this.order.usageMonths.forEach((item, key) => {

                            if(item != 0)
                            {
                                usageMonthsOn ++;
                            }
                        });
                    }
                }
            }

            let finishPrice = 0;
            if (usageMonthsOn > 0) {
                if(usageMonthsOn > 6)
                    usageMonthsOn = 6;
                let proc = this.order.price/100;
                let sumMin = proc * (usageMonthsOn * 5);
                finishPrice = this.order.price - sumMin;
                finishPrice = finishPrice.toFixed(2);
            }
            else
                finishPrice = this.order.price;
            return Number(finishPrice).toFixed(2);;
        },
        showTypeDocument() {
            if (this.order.insurant?.documentation?.type) {
                return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: this.order.insurant.documentation.type })?.name;
            }
        },
        showAutoCategory() {
            if (this.order.insuranceObject.autoCategory) {
                return `(${this.order.insuranceObject.autoCategory})`;
            }
        },

        showCityRegister() {
            if (this.order.insurant.city) {
                return this.order.offer.city?.name_full_name_ru || this.order.insurant.city;
            }
        },
        euroStatus(value){
            $('[data-toggle="tooltip"]').tooltip('hide');
            if(this.euroChecked === true){
                this.city = value;
                $('.city-select').addClass('disabled');
            }else{
                this.city = null;
                $('.city-select').removeClass('disabled');
            }
        },
        isLegalType() {
            //вынести это в миксин
          return this.order.insurant.customerTypeId === 2;
        },
        choseCity(value) {
            this.city = value;
            $(".tooltip").remove();
            this.euroChecked = false;
            $('.params-city-status').removeClass('disabled');
        },
        getContractPeriod(){
            let currentYear = parseInt(moment(this.order.contract.finishedAt,'DD/MM/YYYY').format('YYYY'));
            let currentMonth = parseInt(moment(this.order.contract.finishedAt,'DD/MM/YYYY').format('MM'));
            let currentDay = parseInt(moment(this.order.contract.finishedAt,'DD/MM/YYYY').format('DD'));
            let startYear = parseInt(moment(this.order.contract.startedAt,'DD/MM/YYYY').format('YYYY'));
            let startMonth = parseInt(moment(this.order.contract.startedAt,'DD/MM/YYYY').format('MM'));
            let startDay = parseInt(moment(this.order.contract.startedAt,'DD/MM/YYYY').format('DD'));

            let diffYears = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'years', true);
            let diffMonths = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'months', true);
            let diffDays = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'days', true);


            if(diffYears >= 1){
                let count = Math.round(diffYears);
                if(count >= 2){
                    this.orderPeriod = count+' роки'
                }else if(count >= 5){
                    this.orderPeriod = count+' років'
                }else{
                    this.orderPeriod = count+' рік'
                }
            }else if(diffDays > 15){
                let count = Math.ceil(diffMonths);
                if(count < 5 && count > 1){
                    this.orderPeriod = count+' місяці'
                }else if(count >= 5){
                    this.orderPeriod = count+' місяців'
                }else{
                    this.orderPeriod = count+' місяць'
                }
            }else{
                let count = diffDays;
                if(count <= 15){
                    this.orderPeriod = '15 днів'
                }else{
                    this.orderPeriod = count+' днів'
                }
            }
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

